/* 🌟 Main Section */
.home-featured-scooters {
  background: #c7edf465;
  text-align: center;
  overflow-x: hidden; /* Prevent horizontal overflow */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 12px;
}

/* 🏆 Title */
.home-featured-scooters .featured-scooters-title {
  color: var(--color-primary-dark);
  font-size: 2.2rem;
  font-weight: 700;
  padding-bottom: 20px;
  opacity: 0;
  transform: translateY(30px);
  transition: opacity 0.8s ease-out, transform 0.8s ease-out;
}

.home-featured-scooters .featured-scooters-title.visible {
  opacity: 1;
  transform: translateY(0);
}

/* 🎠 Slider Container */
.home-featured-scooters .slider-container {
  width: 100%;
  position: relative;
  overflow: hidden;
  opacity: 0;
  transform: translateY(40px);
  transition: opacity 0.8s ease-out, transform 0.8s ease-out;
}

.home-featured-scooters .slider-container.visible {
  opacity: 1;
  transform: translateY(0);
}

/* 📍 Individual Scooter Card */
.scooter-card {
  background-color: #c7edf4d2;
  border-radius: 8px;
  padding: 20px;
  gap: 20px;
  text-align: center;
  display: flex !important;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 400px; /* Ensures all cards have the same height */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

/* 🖼 Scooter Image */
.scooter-img {
  width: 360px;
  height: 280px; /* Consistent image height */
  object-fit: cover;
  border-radius: 30px;
  object-position: center;
}
.scooter-img img{
  border-radius: 24px;
}

/* 📜 Scooter Details */
.scooter-details {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
  padding: 10px;
}

.scooter-details h3 {
  font-size: 1.5rem;
  color: var(--color-primary-dark);
  margin-bottom: 10px;
}

/* 🎟 Rent Now Button */
.button {
  text-decoration: none;
  background-color: var(--color-primary-dark);
  color: var(--color-accent-cyan);
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}

.button:hover {
  background-color: var(--color-accent-cyan);
  color: var(--color-primary-dark);
}

/* 🎯 Custom Arrows */
.slick-prev,
.slick-next {
  width: 30px;
  height: 30px;
  background-color: var(--color-primary-dark); /* Dark blue background */
  border-radius: 50%;
  display: flex !important;
  justify-content: center;
  align-items: center;
  z-index: 5;
  transition: all 0.3s ease-in-out;
}

.slick-prev::before,
.slick-next::before {
  font-size: 18px;
  color: var(--color-accent-cyan); /* Light blue arrows */
  opacity: 1;
  transition: color 0.3s ease-in-out;
}

/* 🚀 Smooth Hover Effects */
.slick-prev:hover,
.slick-next:hover {
  background-color: var(--color-accent-cyan); /* Cyan background on hover */
}

.slick-prev:hover::before,
.slick-next:hover::before {
  color: var(--color-primary-dark); /* Dark blue arrow color on hover */
}

/* 📍 Arrow Positioning */
.slick-prev {
  left: 20px !important; /* Adjusted to fit inside smoothly */
}

.slick-next {
  right: 20px !important; /* Adjusted to fit inside smoothly */
}

/* 📱 Responsive Arrow Positioning */
@media (max-width: 768px) {
  .slick-prev {
    left: 3px !important;
  }
  .slick-next {
    right: 3px !important;
  }
  .scooter-img {
    width: 260px;
    height: 240px; /* Consistent image height */
  }
}

@media (max-width: 500px) {
  .slick-prev, .slick-next {
    width: 35px;
    height: 35px;
  }
  
  .slick-prev {
    left: 3px !important;
  }
  
  .slick-next {
    right: 3px !important;
  }
}

/* 🎨 Slick Dots */
.slick-dots {
  bottom: -25px;
}

.slick-dots li button:before {
  font-size: 12px;
  color: var(--color-primary-dark);
}

.slick-dots li.slick-active button:before {
  color: var(--color-primary);
}

.slick-track {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 24px;
  margin: 24px auto;
}

/* 📱 Responsive Design */
@media (max-width: 768px) {
  .home-featured-scooters .featured-scooters-title {
    font-size: 1.8rem;
  }
}

@media (max-width: 500px) {
  .home-featured-scooters .featured-scooters-title {
    font-size: 1.6rem;
  }
}
