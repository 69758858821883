/* Footer.css */
.footer {
  background-color: var(--color-primary-dark);
  padding: 20px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 24px;

  /* z-index: 100; */
}
.links-and-informations{
  margin: 0 auto;
  width: 80%;
  display: flex;
  justify-content: space-evenly;
  gap: 12px;
}
.links-and-informations > div{
  width: 100%;
}
.social-and-copyrights{
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 12px;
}
.copyright{
  text-align: center;
}
@media (max-width: 768px) {
  .links-and-informations{
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    gap: 24px;
  }
  
}