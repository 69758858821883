.about .title,
.about .title-2{
  text-align: center;
  font-size: 42px;
  color: var(--color-primary-dark);
  padding: 12px;
}
.about .call-to-action{
  margin: 21px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 12px;
}
.about .call-to-action h2{
  font-size: 42px;
  color: var(--color-primary-dark);
}
.about .button {
  text-decoration: none;
  background-color: var(--color-primary-dark);
  color: var(--color-accent-cyan);
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  animation-delay: 0.6s;
  transition: all .3s ease-in-out;
}

.about .button:hover {
  color: var(--color-primary-dark);
  background-color: var(--color-accent-cyan);
}
@media (max-width: 768px) {
  .about .title-2{
    font-size: 30px;
    padding: 32px;
  }
  .about .call-to-action h2{
    font-size: 32px;
    text-align: center;
  }
}