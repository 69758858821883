/* 🌟 Hero Section */
.hero {
  position: relative;
  background-color: var(--color-overlay-dark);
  color: var(--color-background-light);
  height: 70vh;
  padding: 60px 20px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

/* 🖼 Background Styling */
.hero-background {
  background-image: url("../../../../images/scooterreservationformimages/things-to-do-in-Skiathos.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  filter: brightness(0.7);
}

/* 🎯 Hero Content */
.hero-content {
  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 24px;
  max-width: 80%;
  text-align: center;
}

/* 🚀 Initial Hidden State (Before Animation) */
.hero-title,
.hero-description,
.hero-button {
  opacity: 0;
  transform: translateY(30px);
  transition: opacity 0.8s ease-out, transform 0.8s ease-out;
}

/* 🎬 When Elements Become Visible */
.hero-title.visible,
.hero-description.visible,
.hero-button.visible {
  opacity: 1;
  transform: translateY(0);
}

/* 🏆 Title */
.hero-title {
  text-shadow: 
    0px 0px 6px var(--color-accent-purple),
    0px 0px 8px var(--color-accent-light-blue),
    0px 0px 12px rgba(255, 255, 255, 0.7);
  color: var(--color-background-light);
  font-size: 3rem;
  font-weight: 700;
  letter-spacing: 1px;
  max-width: 700px;
}

/* 📜 Description */
.hero-description {
  font-size: 1.4rem;
  font-weight: 400;
  max-width: 600px;
  line-height: 1.5;
  color: var(--color-background-light);
}

/* 🎟 Rent Now Button */
.hero-button {
  text-decoration: none;
  background-color: var(--color-accent-cyan);
  color: var(--color-primary-dark);
  padding: 12px 28px;
  border-radius: 8px;
  font-size: 1.2rem;
  font-weight: bold;
  transition: all 0.3s ease-in-out;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.2);
}

.hero-button:hover {
  background-color: var(--color-primary);
  color: var(--color-background-light);
  transform: translateY(-3px);
  box-shadow: 0px 6px 14px rgba(0, 0, 0, 0.25);
}

/* 📱 Responsive Design */
@media (max-width: 1024px) {
  .hero {
    height: 65vh;
  }
  .hero-title {
    font-size: 2.5rem;
  }
  .hero-description {
    font-size: 1.2rem;
  }
}

@media (max-width: 768px) {
  .hero {
    height: 60vh;
  }
  .hero-title {
    font-size: 2rem;
  }
  .hero-description {
    font-size: 1.1rem;
  }
  .hero-button {
    padding: 10px 24px;
    font-size: 1rem;
  }
}

@media (max-width: 480px) {
  .hero {
    height: 55vh;
  }
  .hero-title {
    font-size: 1.8rem;
  }
  .hero-description {
    font-size: 1rem;
  }
  .hero-button {
    padding: 8px 20px;
    font-size: 0.9rem;
  }
}
