/* 📜 Copyright Text */
.copyright {
  font-size: 1rem;
  color: var(--color-accent-cyan);
  text-align: center;
  opacity: 0;
  transform: translateY(20px);
  transition: opacity 0.8s ease-out, transform 0.8s ease-out;
}

/* ✅ When it enters the viewport */
.copyright.visible {
  opacity: 1;
  transform: translateY(0);
}

/* 📱 Responsive Adjustments */
@media (max-width: 768px) {
  .copyright {
    font-size: 0.9rem;
  }
}
