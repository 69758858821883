/* General page styling */
.scooter-rental-page {
  padding: 20px;
  margin: 0 auto;
}
.scooter-rental-page .title {
  font-size: 42px;
  color: var(--color-primary-dark);
  text-align: center;
  padding: 42px 12px 21px 12px;
}
/* Grid layout for the scooter preview container */
.scooter-preview-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
  margin-bottom: 40px;
}

/* Individual scooter preview styling */
.scooter-preview {
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 15px;
  text-align: center;
}

/* Adjust slider image styling */
.scooter-preview .slider-container img {
  width: 100%;
  height: auto;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

/* Instruction and insurance sections */
.instructions-section,
.insurance-section {
  background-color: #f9f9f9;
  padding: 20px;
  border-radius: 10px;
  margin-bottom: 40px;
}
@media (max-width: 768px) {
  .scooter-rental-page .title {
    font-size: 35px;
  }
}
@media (max-width: 560px) {
 .scooter-preview-container{
    grid-template-columns: 1fr;
 }
 .scooter-rental-page .title {
  font-size: 24px;
}
}