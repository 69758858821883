.scooter-reservation-form {
  position: relative;
  width: 100%;
  min-height: 100vh; /* Ensure the background covers the full viewport height */
  background-image: url('../../../../images/scooterreservationformimages/things-to-do-in-Skiathos.jpg'); /* Replace with your image path */
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  display: flex;
  justify-content: center;
  align-items: center;

  padding: 150px 20px;
}

.background-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: var(--color-overlay-dark); /* Dark overlay to enhance text readability */
  z-index: 1;
  opacity: 0.5; /* Adjust opacity for desired effect */
}

.reservation-form {
  position: relative;
  z-index: 2; /* Ensure the form is above the background and overlay */
  max-width: 500px;
  width: 100%;
  padding: 20px;
  background-color: #111d3ab8;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  font-family: Arial, sans-serif;
}

.reservation-form h2 {
  text-align: center;
  margin-bottom: 20px;
  color: var(--color-accent-cyan);
}

.form-group {
  margin-bottom: 15px;
}

.form-group label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
  color: var(--color-accent-cyan);
}

.form-group input,
.form-group select {
  width: 100%;
  padding: 8px;
  border: 1px solid var(--color-accent-cyan);
  border-radius: 5px;
  font-size: 16px;
  box-sizing: border-box;
  background-color: #d9f5fbd9;
  color: var(--color-primary-dark);
}

.form-group input[type="checkbox"] {
  width: auto;
  margin-right: 10px;
}

.form-group .error {
  color: rgb(232, 133, 133);
  font-size: 14px;
  margin-top: 5px;
}

button[type="submit"] {
  width: 100%;
  padding: 10px;
  background-color: var(--color-primary);
  color: var(--color-background-light);
  border: none;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.3s ease;
}

button[type="submit"]:hover {
  background-color: var(--color-accent-purple);
  transform: translateY(-2px);
}

button[type="submit"]:active {
  background-color: var(--color-primary-dark);
  transform: translateY(0);
}

button[type="submit"]:focus {
  outline: 3px solid var(--color-accent-cyan);
}
.form-status{
  text-align: center;
  margin: 20px auto 5px auto;
  color: red;
}
/* Responsive adjustments */
@media (max-width: 600px) {
  .reservation-form {
    padding: 15px;
  }
}