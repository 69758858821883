.about-section {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 40px;
  align-items: center;
  padding: 40px;
  max-width: 1200px;
  margin: 0 auto;
}

.images-container {
  position: relative;
}

.image-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, 1fr);
  gap: 20px;
  position: relative;
}

.grid-image {
  width: 100%;
  height: auto;
  object-fit: cover;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.image1 {
  grid-column: 1 / 2;
  grid-row: 1 / 2;
  height: 150px; /* Shorter but wider */
}

.image2 {
  grid-column: 2 / 3;
  grid-row: 1 / 3;
  height: 320px; /* Taller but less width */
}

.image3 {
  grid-column: 1 / 2;
  grid-row: 2 / 3;
  height: 150px; /* Small square */
}

.image4 {
  grid-column: 1 / 3;
  grid-row: 3 / 4;
  height: 320px; /* Big square */
}

.text-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.text-container h3 {
  font-size: 1rem;
  color:var(--color-background-light);
  padding: 3px 5px;
  background-color: var(--color-accent-purple);
  text-transform: uppercase;
  margin-bottom: 6px;
  width: fit-content;
}

.text-container h2 {
  font-size: 2.5rem;
  color: var(--color-primary-dark);
  margin-bottom: 20px;
}

.text-container p {
  font-size: 1rem;
  color: var(--color-primary-dark);
  line-height: 1.5;
  margin-bottom: 10px;
}

/* Responsive adjustments */
@media (max-width: 1080px) {
  .about-section{
    gap: 18px;
  }
  .text-container h3{
    font-size: .6rem;
  }
  .text-container h2{
    font-size: 2.1rem;
  }
  .text-container p{
    font-size: .9rem;

  }
}
/* 🚀 Default animation setup */
.animate-section,
.animate {
  opacity: 0;
  transform: translateY(30px);
  transition: opacity 0.8s ease-out, transform 0.8s ease-out;
}

/* ✅ When elements enter viewport */
.visible {
  opacity: 1;
  transform: translateY(0);
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .about-section {
    padding: 12px;
    grid-template-columns: 1fr;
    gap: 6px;
  }
  .image-grid{
    gap: 6px;
  }
  .overlay-container {
    padding: 20px;
  }
  .text-container{
    text-align: center;
  }
  .experience-text .years {
    font-size: 2rem;
  }

  .experience-text .description {
    font-size: 1rem;
  }

  .text-container h2 {
    font-size: 2rem;
  }

}
